// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as BankingReady_Foreign_Excel_Range from "../BankingReady.Foreign.Excel.Range/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number_Format from "../Data.Number.Format/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_TraversableWithIndex from "../Data.TraversableWithIndex/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(Data_String_CodePoints.ordCodePoint);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var showExcelWorksheet = /* #__PURE__ */ BankingReady_Data_Route.showExcelWorksheet(BankingReady_Data_Route.showExcelWorksheetRoute);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var forWithIndex = /* #__PURE__ */ Data_TraversableWithIndex.forWithIndex(Effect.applicativeEffect)(Data_TraversableWithIndex.traversableWithIndexArray);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var for_ = /* #__PURE__ */ Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray);
var calcWordWidth = function ($copy_letterWidth) {
    return function ($copy_word) {
        return function ($copy_currentWidth) {
            var $tco_var_letterWidth = $copy_letterWidth;
            var $tco_var_word = $copy_word;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(letterWidth, word, currentWidth) {
                var v = Data_String_CodePoints.uncons(word);
                if (v instanceof Data_Maybe.Nothing) {
                    $tco_done = true;
                    return currentWidth;
                };
                if (v instanceof Data_Maybe.Just) {
                    var charWidth = (function () {
                        var v1 = lookup(v.value0.head)(letterWidth);
                        if (v1 instanceof Data_Maybe.Nothing) {
                            return 14.0;
                        };
                        if (v1 instanceof Data_Maybe.Just) {
                            return v1.value0;
                        };
                        throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.General (line 48, column 19 - line 50, column 28): " + [ v1.constructor.name ]);
                    })();
                    $tco_var_letterWidth = letterWidth;
                    $tco_var_word = v.value0.tail;
                    $copy_currentWidth = currentWidth + charWidth;
                    return;
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.General (line 44, column 47 - line 52, column 62): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_letterWidth, $tco_var_word, $copy_currentWidth);
            };
            return $tco_result;
        };
    };
};
var toBoxText = function ($copy_letterWidth) {
    return function ($copy_remainingWords) {
        return function ($copy_maxWidth) {
            return function ($copy_lineWidth) {
                return function ($copy_lineText) {
                    return function ($copy_boxText) {
                        var $tco_var_letterWidth = $copy_letterWidth;
                        var $tco_var_remainingWords = $copy_remainingWords;
                        var $tco_var_maxWidth = $copy_maxWidth;
                        var $tco_var_lineWidth = $copy_lineWidth;
                        var $tco_var_lineText = $copy_lineText;
                        var $tco_done = false;
                        var $tco_result;
                        function $tco_loop(letterWidth, remainingWords, maxWidth, lineWidth, lineText, boxText) {
                            var v = Data_Array.uncons(remainingWords);
                            if (v instanceof Data_Maybe.Nothing) {
                                $tco_done = true;
                                return append(boxText)([ lineText ]);
                            };
                            if (v instanceof Data_Maybe.Just) {
                                var wordWidth = calcWordWidth(letterWidth)(v.value0.head)(0.0);
                                var spaceWidth = (function () {
                                    var v1 = lookup(Data_String_CodePoints.codePointFromChar(" "))(letterWidth);
                                    if (v1 instanceof Data_Maybe.Nothing) {
                                        return 10.0;
                                    };
                                    if (v1 instanceof Data_Maybe.Just) {
                                        return v1.value0;
                                    };
                                    throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.General (line 60, column 20 - line 62, column 28): " + [ v1.constructor.name ]);
                                })();
                                var $39 = maxWidth < lineWidth + wordWidth + spaceWidth;
                                if ($39) {
                                    $tco_var_letterWidth = letterWidth;
                                    $tco_var_remainingWords = append([ v.value0.head ])(v.value0.tail);
                                    $tco_var_maxWidth = maxWidth;
                                    $tco_var_lineWidth = 0.0;
                                    $tco_var_lineText = "";
                                    $copy_boxText = append(boxText)([ lineText ]);
                                    return;
                                };
                                $tco_var_letterWidth = letterWidth;
                                $tco_var_remainingWords = v.value0.tail;
                                $tco_var_maxWidth = maxWidth;
                                $tco_var_lineWidth = lineWidth + wordWidth + spaceWidth;
                                $tco_var_lineText = lineText + (v.value0.head + " ");
                                $copy_boxText = boxText;
                                return;
                            };
                            throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.General (line 55, column 76 - line 67, column 113): " + [ v.constructor.name ]);
                        };
                        while (!$tco_done) {
                            $tco_result = $tco_loop($tco_var_letterWidth, $tco_var_remainingWords, $tco_var_maxWidth, $tco_var_lineWidth, $tco_var_lineText, $copy_boxText);
                        };
                        return $tco_result;
                    };
                };
            };
        };
    };
};
var cleanGeneralCommandEffect = function (v) {
    return function (v1) {
        return function (v2) {
            return function (v3) {
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoxText) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var words = Data_String_Common.split(" ")(v3.value0.text);
                    var boxText = toBoxText(v1.defaultFontWidth)(words)(915.0)(0.0)("")([  ]);
                    var textRows = Data_Array.length(boxText);
                    var emptyTextArray = map(function (v4) {
                        return "";
                    })(Data_Array.range(1)(textRows));
                    return function __do() {
                        forWithIndex(emptyTextArray)(function (idx) {
                            return function (elem) {
                                return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(1 + idx | 0)(2)(elem);
                            };
                        })();
                        return Data_Unit.unit;
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertText) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertTempText) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)("");
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoldText) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertItalicText) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertNumber) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertDate) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertFormula) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Border.removeHighlightBorder(worksheet)(v3.value0.rangeSelector)(v3.value0.rangeType)();
                        for_(v3.value0.references)(function (ref) {
                            return BankingReady_Utility_Excel_Border.removeHighlightBorder(worksheet)(ref.rangeSelector)(ref.rangeType);
                        })();
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.formula)();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertInputNumber) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertInputCell) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertHeader) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertSubHeader) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertTotal) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoldLine) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertItalicLine) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoldItalicLine) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.Highlight) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Border.removeHighlightBorder(worksheet)(v3.value0.rangeSelector)(v3.value0.rangeType);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.DrawOuterBorder) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.DrawBottomBorder) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.CenterAlignCell) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.CopyRight) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.CopyRightOnClean) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        for_(Data_Array.range(v3.value0.rangeSelector.rowIdx)((v3.value0.rangeSelector.rowIdx + v3.value0.rangeSelector.rowCount | 0) - 1 | 0))(function (rowIdx) {
                            var sourceRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(v3.value0.rangeSelector.colIdx)(1)(1);
                            var targetRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(v3.value0.rangeSelector.colIdx)(1)(v3.value0.rangeSelector.columnCount);
                            return BankingReady_Foreign_Excel_Range.copyFrom(targetRange)(sourceRange)(BankingReady_Foreign_Excel.CopyTypeAll.value);
                        })();
                        return Data_Unit.unit;
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.SetBoldFalse) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.Scroll) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Foreign_Excel_Worksheet.activate(worksheet)();
                        var newActiveCell = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(v3.value0.rowIdx)(1)(1)(1);
                        return BankingReady_Foreign_Excel_Range.select(newActiveCell)();
                    };
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.General (line 350, column 1 - line 351, column 71): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name ]);
            };
        };
    };
};
var postGeneralCommandEffect = function (v) {
    return function (v1) {
        return function (v2) {
            return function (v3) {
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoxText) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var words = Data_String_Common.split(" ")(v3.value0.text);
                    var boxText = toBoxText(v1.defaultFontWidth)(words)(915.0)(0.0)("")([  ]);
                    return function __do() {
                        forWithIndex(boxText)(function (idx) {
                            return function (elem) {
                                return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(1 + idx | 0)(2)(elem);
                            };
                        })();
                        return Data_Unit.unit;
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertText) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.text);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertTempText) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.text);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoldText) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.text)();
                        return BankingReady_Utility_Excel_Cell.setCellFontBold(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(true)();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertItalicText) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.text)();
                        return BankingReady_Utility_Excel_Cell.setCellFontItalic(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(true)();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertNumber) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(Data_Number_Format.toString(v3.value0.number))();
                        return BankingReady_Utility_Excel_Cell.setCellNumberFormat(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.numberFormat)();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertDate) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.date)();
                        return BankingReady_Utility_Excel_Cell.setCellNumberFormat(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.dateFormat)();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertFormula) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.formula)();
                        return BankingReady_Utility_Excel_Cell.setCellNumberFormat(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.numberFormat)();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Border.highlightBorder(worksheet)(v3.value0.rangeSelector)(BankingReady_Utility_Excel_Border.Green.value)();
                        for_(v3.value0.references)(function (ref) {
                            return BankingReady_Utility_Excel_Border.highlightBorder(worksheet)(ref.rangeSelector)(BankingReady_Utility_Excel_Border.Green.value);
                        })();
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)("'" + v3.value0.formula)();
                        return BankingReady_Utility_Excel_Cell.setCellNumberFormat(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.numberFormat)();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertInputNumber) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(Data_Number_Format.toString(v3.value0.number))();
                        BankingReady_Utility_Excel_Cell.setCellNumberFormat(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(v3.value0.numberFormat)();
                        BankingReady_Utility_Excel_Cell.setCellFillColor(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)("#fffbeb")();
                        return BankingReady_Utility_Excel_Cell.setCellFontColor(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)("#1d4ed8")();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertInputCell) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellFillColor(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)("#fffbeb")();
                        return BankingReady_Utility_Excel_Cell.setCellFontColor(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)("#1d4ed8")();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertHeader) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.title)();
                        return for_(Data_Array.range(1)(v3.value0.rangeSelector.columnCount))(function (j) {
                            return function __do() {
                                BankingReady_Utility_Excel_Cell.setCellFillColor(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)("#305496")();
                                BankingReady_Utility_Excel_Cell.setCellFontColor(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)("#FFFFFF")();
                                return BankingReady_Utility_Excel_Cell.setCellFontBold(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)(true)();
                            };
                        })();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertSubHeader) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.title)();
                        return for_(Data_Array.range(1)(v3.value0.rangeSelector.columnCount))(function (j) {
                            return function __do() {
                                BankingReady_Utility_Excel_Cell.setCellFillColor(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)("#D9E1F2")();
                                return BankingReady_Utility_Excel_Cell.setCellFontBold(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)(true)();
                            };
                        })();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertTotal) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.text)();
                        BankingReady_Utility_Excel_Border.drawTopBorder(worksheet)(v3.value0.rangeSelector)();
                        return for_(Data_Array.range(1)(v3.value0.rangeSelector.columnCount))(function (j) {
                            return BankingReady_Utility_Excel_Cell.setCellFontBold(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)(true);
                        })();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoldLine) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.text)();
                        return for_(Data_Array.range(1)(v3.value0.rangeSelector.columnCount))(function (j) {
                            return BankingReady_Utility_Excel_Cell.setCellFontBold(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)(true);
                        })();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertItalicLine) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.text)();
                        return for_(Data_Array.range(1)(v3.value0.rangeSelector.columnCount))(function (j) {
                            return BankingReady_Utility_Excel_Cell.setCellFontItalic(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)(true);
                        })();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.InsertBoldItalicLine) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx)(v3.value0.text)();
                        return for_(Data_Array.range(1)(v3.value0.rangeSelector.columnCount))(function (j) {
                            return function __do() {
                                BankingReady_Utility_Excel_Cell.setCellFontBold(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)(true)();
                                return BankingReady_Utility_Excel_Cell.setCellFontItalic(worksheet)(v3.value0.rangeSelector.rowIdx)(v3.value0.rangeSelector.colIdx + (j - 1 | 0) | 0)(true)();
                            };
                        })();
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.Highlight) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Border.highlightBorder(worksheet)(v3.value0.rangeSelector)(BankingReady_Utility_Excel_Border.Green.value);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.DrawOuterBorder) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Border.highlightBorder(worksheet)(v3.value0.rangeSelector)(BankingReady_Utility_Excel_Border.Black.value);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.DrawBottomBorder) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Border.drawBottomBorder(worksheet)(v3.value0.rangeSelector);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.CenterAlignCell) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Cell.setCellHorizontalAlignment(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)("Center");
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.CopyRight) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        for_(Data_Array.range(v3.value0.rangeSelector.rowIdx)((v3.value0.rangeSelector.rowIdx + v3.value0.rangeSelector.rowCount | 0) - 1 | 0))(function (rowIdx) {
                            var sourceRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(v3.value0.rangeSelector.colIdx)(1)(1);
                            var targetRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(v3.value0.rangeSelector.colIdx)(1)(v3.value0.rangeSelector.columnCount);
                            return BankingReady_Foreign_Excel_Range.copyFrom(targetRange)(sourceRange)(BankingReady_Foreign_Excel.CopyTypeAll.value);
                        })();
                        return Data_Unit.unit;
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.CopyRightOnClean) {
                    return pure(Data_Unit.unit);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.SetBoldFalse) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Utility_Excel_Cell.setCellFontBold(worksheet)(v3.value0.rowIdx)(v3.value0.colIdx)(false);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.Scroll) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return function __do() {
                        BankingReady_Foreign_Excel_Worksheet.activate(worksheet)();
                        var newActiveCell = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(v3.value0.rowIdx)(1)(v3.value0.rowCount)(1);
                        return BankingReady_Foreign_Excel_Range.select(newActiveCell)();
                    };
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.General (line 69, column 1 - line 70, column 71): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name ]);
            };
        };
    };
};
export {
    calcWordWidth,
    toBoxText,
    postGeneralCommandEffect,
    cleanGeneralCommandEffect
};
