// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var costOfEquityDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The cost of equity is often a subject discussed in interviews as a way of testing a candidate's deeper understanding of the DCF concept. Recall in the last section that the Cost of Equity is the opportunity cost for the common shareholders in the business."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In this section, we will delve deeper into how the Cost of Equity is calculated, unlike the Cost of Debt which is more easily observed by taking a rate related to a debt instrument like the interest rate or yield."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Recall, that the benefits the common shareholder can get are from the stock price appreciation and from any dividends that the company would pay. Well, our Cost of Equity will tell us what we should expect from the long-term average return. Of course, in practice, this may not be the case, but we should expect the long-term average to prevail."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To estimate the Cost of Equity we use the Capital Asset Pricing Model (CAPM) which is a financial model that calculates the expected rate of return for an asset or investment."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 5,
        colIdx: 2,
        text: "Capital Asset Pricing Model (CAPM)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 6,
        colIdx: 3,
        text: "The financial model to value assets and investments"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "From the CAPM we get a formula for the Cost of Equity. The explanation of how we derive this formula is not tested and is too advanced for this course."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Cost of Equity = Risk-Free Rate + Equity Risk Premium * Levered Beta. We will now break down each component and how we would calculate the components."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 8,
        colIdx: 2,
        text: "Cost of Equity = Risk-Free Rate + Equity Risk Premium * Levered Beta"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Starting with the Risk-Free Rate (r), this represents the return that can be achieved on a riskless asset. Now there are very few, if any, completely risk-free assets but the assets with the least risk would include government bonds denominated in the same currency as our financial statements. It is common to see the US Treasuries used as the asset, but it would depend on the company."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 10,
        colIdx: 2,
        text: "Risk-Free Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 3,
        text: "The return on a riskless asset, we use government bonds that are deonimated in the same currency as our company's statements"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 3,
        text: "Germany Company -----> US major place of operates + financials recorded in USD -----> US Treasuries"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 3,
        text: "British Company -----> UK is the only place of operation + financials recorded in GBP -----> British Gilts"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now if we had a German company that had predominately US customers and records its financials in US Dollars, it would be prudent to use the US Treasuries, but if say we had a British company that only operates domestically and reports in Sterling, it would be best to use the British Gilts."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we will discuss Levered Beta. Those of you who are familiar with statistics will already have a grasp of Beta as a concept. In finance, we use it as a measure of volatility between 2 assets, notably the volatility of a stock relative to the market."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 16,
        colIdx: 2,
        text: "Beta"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 17,
        colIdx: 3,
        text: "A measure of volatility of a stock relative to the market"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 3,
        text: "Levered: Factoring in intrinsic business risk and risk arising from leverage (debt)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 3,
        text: "Unlevered Factoring in intrinsic business risk, often similar to peers"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "A distinction worth mentioning is the difference between Levered and Unlevered Beta. Levered (deriving from leverage) means the measure of volatility factors in both the intrinsic business risk and the risk introduced by the leverage or Debt the business carries. Unlevered Beta would look exclusively at the business risk of the company without factoring in the Debt. Therefore, if we look at the Unlevered Betas of companies in the same industries, they are often very similar."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To get to the Beta of a company we often would look at the Unlevered Beta of its peers. For example, Kellogg, could include Kraft Heinz and Mondelez and see what their Unlevered Betas are. We often would take an average of those peers and then use our company\u2019s capital structure  re-lever Beta to arrive at an estimate of Kellogg\u2019s Levered Beta."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 21,
        colIdx: 2,
        text: "Unlevered Peer Betas"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 3,
        text: "'-----> Find average to assess the market risk "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 23,
        colIdx: 3,
        text: "'-----> Re-lever for our company's capital structure to include the leverage risk "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 24,
        colIdx: 3,
        text: "-----> Levered Beta"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Levered Beta = Unlevered Beta * (1 + (1 \u2013 Tax Rate) * Debt/Equity). This can also be rearranged to find Unlevered Beta if necessary. The reason we include the Tax Rate is that the risk to the business from taking on debt (and making repayments) is slightly offset by the additional tax savings we make although do not outweigh the additional risk of taking on debt."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 26,
        colIdx: 2,
        text: "Levered Beta = Unlevered Beta * (1 + (1-Tax Rate) * Debt/Equity)"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Once we have calculated Levered Beta it is worth knowing how to interpret it. As previously mentioned, it tells us how volatile a stock is relative to the market. We explain this through some examples. If a company has a Beta of 1, this means the stock is as volatile as the market. If the market is up 10% the stock is also up 10%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Example"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 29,
        colIdx: 2,
        text: "Beta"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 4,
        number: 1.0,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 30,
        colIdx: 2,
        text: "Stock Market Return"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 30,
        colIdx: 4,
        number: 0.1,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Stock Return"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 31,
        colIdx: 4,
        formula: "=E30*E31",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 2,
            rowCount: 4,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now if Beta is less than 1, this means the stock is less volatile than the market and or does not move as extremely. Let\u2019s say the Beta is 0.75 and the market is up 10%. In this case, the stock would be up 7.5%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 4,
        number: 0.75,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 2,
            rowCount: 4,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "If Beta becomes greater than 1, this means the stock is more volatile than the market or moves more extremely. Let\u2019s say the Beta is 1.5 and the market is up 10%. In this case, the stock would be up 15%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 4,
        number: 1.5,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 2,
            rowCount: 4,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, what would be the case if Beta was negative? Well, we can think of this as an opposite relationship in terms of direction. For example, if the market is up the stock would be down and vice versa. For example, let\u2019s say the Beta is -0.5 and the market is up 10%. Well in this case our stock would be DOWN 5%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 4,
        number: -0.5,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 2,
            rowCount: 4,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we have the Equity Risk Premium, the expected percentage the stock market will return in excess of the risk-free rate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 33,
        colIdx: 2,
        text: "Equity Risk Premium"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 34,
        colIdx: 3,
        text: "The percentage the stock market will return each year, on average, above the yield of the risk-free rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 36,
        colIdx: 2,
        text: "Sources: Statista, Bloomberg, Refinitiv, Accounting Reports, "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 37,
        colIdx: 2,
        text: "'-----> find an average "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 38,
        colIdx: 2,
        text: "'-----> remember to record the figure in excess of risk-free rate"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Some sources often used are Statista, Bloomberg, Refinitiv, Accounting Reports, Damodaran website and annual reports."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now importantly, whatever the return we use is, we must ensure it is the excess above the risk-free rate. For example, if our historical Equity Return is 10% and the risk-free rate is 2.5% then the Equity Risk Premium is 7.5%."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can run through an example. Let us say that we have found the average Unlevered Beta of its peers to be 0.8. Our company has 40% Debt with a current Yield-to-Maturity of 7% and operates primarily within the U.K. which has a corporate tax rate of 25%. The current yield for the US T-10 is 4.5%, the Euro-10 is 6% and British Gilt is 4%. Historically, the market has returned 12.5%. What is the Cost of Equity and WACC?"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Assumptions"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 41,
        colIdx: 2,
        text: "Risk Free Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 41,
        colIdx: 4
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 42,
        colIdx: 2,
        text: "Unlevered Beta"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 42,
        colIdx: 4
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 43,
        colIdx: 2,
        text: "Market Return"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 43,
        colIdx: 4
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 44,
        colIdx: 2,
        text: "Debt %"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 44,
        colIdx: 4
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 45,
        colIdx: 2,
        text: "Debt YTM"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 45,
        colIdx: 4
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 46,
        colIdx: 2,
        text: "Tax Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 46,
        colIdx: 4
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 47,
        colIdx: 2,
        text: "Equity %"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 49,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Calculations"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 50,
        colIdx: 2,
        text: "Equity Risk Premium"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 51,
        colIdx: 2,
        text: "Levered Beta"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 52,
        colIdx: 2,
        text: "Risk-Free Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 53,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Cost of Equity"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 55,
        colIdx: 2,
        text: "Cost of Debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 56,
        colIdx: 2,
        text: "Debt %"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 57,
        colIdx: 2,
        text: "Equity %"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 58,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "WACC"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 2,
            rowCount: 19,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Well first let us set up the inputs for the Cost of Equity. Recall, the risk-free rate is the return on the riskless government asset. In our case, we use the British Gilt since the company operates here, which is 4%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 41,
        colIdx: 4,
        number: 4.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 41,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The Beta, Market Return, Debt Proportions and Tax Rate are given straight from the question. Recall, that for the Cost of Debt we use in the WACC formula will be the Yield-To-Maturity on the Debt instruments the company has."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 42,
        colIdx: 4,
        number: 0.8,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 43,
        colIdx: 4,
        number: 0.13,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 44,
        colIdx: 4,
        number: 0.4,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 45,
        colIdx: 4,
        number: 7.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 46,
        colIdx: 4,
        number: 0.25,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 47,
        colIdx: 4,
        formula: "=1-E45",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 42,
            colIdx: 4,
            rowCount: 6,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Remember, when we calculate the Equity Risk Premium, we are looking at the return in excess of the Risk-Free Rate. Therefore, our Equity Risk Premium is 13% - 4% = 9%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 50,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=E44-E42",
        references: [ {
            rangeSelector: {
                rowIdx: 41,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 43,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we have to re-lever Beta for our company\u2019s Debt. Using the highlighted formula, we can see the Levered Beta = (1 + (1 - 25%) * 40%/60%) = 1.20. This is our Levered Beta."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 51,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=E43*(1+(1-E47)*E45/E48)",
        references: [ {
            rangeSelector: {
                rowIdx: 42,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 44,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 46,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 47,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Since we are already given the Risk-Free Rate, we can just link it to the assumption cell."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 52,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=E42",
        references: [ {
            rangeSelector: {
                rowIdx: 41,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we have all the calculated components we require to find the Cost of Equity. Using the formula, we first introduced in this section we can see that Cost of Equity = 4% + (9%) * 1.20 = 14.8%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 53,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=E53+E51*E52",
        references: [ {
            rangeSelector: {
                rowIdx: 50,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 51,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 52,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can reuse the Debt and Equity proportions with the newly calculated Cost of Equity and the previously discussed Cost of Debt to calculate the WACC. This involves weighing the Costs by their proportions. Therefore, our WACC = 14.80% * 60% + 7% * 40% * (1 - 25%) = 11.0%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 55,
        colIdx: 4,
        formula: "=E46",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 56,
        colIdx: 4,
        formula: "=E45",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 57,
        colIdx: 4,
        formula: "=E48",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 58,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=E54*E58+E56*E57*(1-E47)",
        references: [ {
            rangeSelector: {
                rowIdx: 46,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 53,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 55,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 56,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 57,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now, the final point worth mentioning is due to the range of assumptions we have made regarding the inputs to create our WACC we often conduct a sensitivity analysis on the key inputs to see how wide our calculations could be if the inputs were to be different."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To recap, when we calculate the Cost of Equity, we rely on the CAPM model and estimate the Risk-Free Rate, the Equity Risk Premium and the company\u2019s Levered Beta. We have discussed how to find all of these components and how they are incorporated into a WACC calculation."
    })) ] ];
})();
export {
    costOfEquityDescriptions
};
