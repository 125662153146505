// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var businessProjectionsDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When modelling the operations of a company we need to understand what the drivers are for a company\u2019s performance. In this lesson, we focus on what drives the revenue and expenses of a company. We can then use these drivers to model the projections for the financials of our company."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When working on a full model it is always important to keep in mind that we must follow a business logic in how we break down our numbers."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For example, if we take Kellogg\u2019s revenue, we can see it has been split geographically."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "If you are in a case study or assessment centre and have been asked to produce a model, then you must decide on your approach based on the time you have available. For example, if you do not have an extended period to build a model then your best course is to forecast using simple percentage-based assumptions for the growth rates and margins."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Let\u2019s quickly recap how to calculate the historic growth rate for the various segments and years. Let\u2019s do this for the North America segment in 2019. We take the current period\u2019s revenue and divide it by the previous year\u2019s minus 1."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=G8/F8-1",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can see that the historic growth rate from 2018 to 2019 for North America sales is ($8,483/$8,688) - 1 = -2.4%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "As we have shown in previous modules, we can quickly copy this formula for the remaining years by highlighting our formulae and the cells to the right that we want to copy into and using CTRL + R to copy our formula to the right."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can keep these cells highlighted and copy and paste them into the rows for the other segments."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Excel will keep the relative position of our references so it will take the growth rate from the cell below and the previous revenue from the cell to the left."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 28,
        colIdx: 6,
        formula: "=G28/F28-1",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 32,
        colIdx: 6,
        formula: "=G32/F32-1",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 36,
        colIdx: 6,
        formula: "=G36/F36-1",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "If you have somewhat more time then you may be able to model some line items in detail such as Revenue, and the components of the COGS and Opex. Of course, if you have all the time, then a full treatment of each line item is best. We do not (and interviewers do not) expect you to complete a full model."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Starting from the simplest level, we could build a simple revenue model by assuming a growth rate for each regional segment."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For example, if we assumed a 2.5% growth each year, we could forecast the regional levels."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 8,
        colIdx: 9,
        number: 2.5e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To calculate this, we would take 1 plus our growth rate and multiply it by the previous year\u2019s revenue."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I8*(1+J9)",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So (1+2.5%)*$8,817 = $9,037 which is our projected 2022 revenues for the North America segment."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "As we showed earlier, we can highlight our formula cells and the cells to the right and use CTRL + R to copy our formula to the right."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 9,
            rowCount: 2,
            columnCount: 8
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 9,
            rowCount: 2,
            columnCount: 8
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In doing so we would find that the North America sales of the company increase each period by 2.5%."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Of course, in practice, this approach can be more informed, such as through using the average of the previous 3 years or using market data (if available). We can do this using the AVERAGE formula and highlight the last 3 years of growth rates."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 9,
        text: ""
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 9,
        text: ""
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 9,
            rowCount: 2,
            columnCount: 8
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we calculate the average for the North American region. This involves taking the average of -2.4%, -2.7%, 6.8%. We refer to these cells as $F$9:$H$9 in the formula so if we copy across the average formula, we are still referencing these cells for the average."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=AVERAGE($G$9:$I$9)",
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 6,
                rowCount: 1,
                columnCount: 3
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us an average growth rate of 0.6%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we can follow the same steps as above to calculate the average growth rate for Europe This involves taking the average of -1.5%, 6.7%, and 7.3%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 28,
        colIdx: 9
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=AVERAGE($G$29:$I$29)",
        references: [ {
            rangeSelector: {
                rowIdx: 28,
                colIdx: 6,
                rowCount: 1,
                columnCount: 3
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us an average growth rate of 4.2%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we can follow the same steps as above to calculate the average growth rate for Latin America This involves taking the average of -0.6%, -2.9%, and 9.1%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 32,
        colIdx: 9
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=AVERAGE($G$33:$I$33)",
        references: [ {
            rangeSelector: {
                rowIdx: 32,
                colIdx: 6,
                rowCount: 1,
                columnCount: 3
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us an average growth rate of 1.9%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can follow the same steps as above to calculate the average growth rate for Africa, the Middle East and Asia (AMEA). This involves taking the average of 20.4%, 5.0%, and 15.5%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 36,
        colIdx: 9
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=AVERAGE($G$37:$I$37)",
        references: [ {
            rangeSelector: {
                rowIdx: 36,
                colIdx: 6,
                rowCount: 1,
                columnCount: 3
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us an average growth rate of 13.6%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In the workplace, we would often use the consensus from broker reports to guide us in the near term and then make conservative judgements about the later periods. We often tail off any major growth to be conservative and assume that the high growth cannot be sustained forever."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Let\u2019s adjust some of our growth rates to reflect a more sustainable growth plan."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, let\u2019s forecast for North America. Now this is the most mature market and is experiencing modest growth. For this segment, we think it's appropriate to assume the same growth rate, in line with the historical average (known as straight lining)."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To do this we link the growth rate in the following periods (2023 onwards) to the average we calculated as our 2022 growth rate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 8,
        colIdx: 10
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J9",
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can then calculate the revenue our growth rate assumptions achieve. Take our 2021 revenue and multiply by (1 + 0.59%), the 2022 growth rate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I8*(1+J9)",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our 2022 revenue forecast is $8,817*(1+0.6%) = $8,869"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Since we have the 2023 Growth Rate we can copy right the North America sales formula 1 cell to the right."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 9,
            rowCount: 1,
            columnCount: 2
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, using the copy-right shortcut we can highlight the revenue formula, our growth rate below and the cells in the remaining periods. We then press CTRL + R."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This results in our growth rate copying across unchanged and revenue for 2029 grows to $9,240."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, let\u2019s forecast for Europe. Now this market has experienced significant growth recently and to be conservative we can adjust the growth rate to taper throughout the projection period. For this segment, let\u2019s assume it can maintain this average growth rate until 2024, and after it will taper by 1.5% over the next 3 years before becoming stable as we end our projections."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To do this we can include an additional row for a growth rate step which we can input the adjustments for each period we want."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Here we can put the adjustments for our growth rate in 2025, 2026 and 2027. We want the growth rate to decrease so we input the growth rate to decline by -0.5%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 10,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 11,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 12,
        number: -5.0e-3,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 13,
        number: -5.0e-3,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 14,
        number: -5.0e-3,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 15,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 29,
        colIdx: 16,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can follow similar steps to when we calculated the America segment growth rate.  We link our 2023 growth rate but also add an adjustment for our growth rate step."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 28,
        colIdx: 10
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J29+K30",
        references: [ {
            rangeSelector: {
                rowIdx: 28,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 10,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In 2023 there is no adjustment, so the growth rate is unchanged. Now, we can calculate our revenues."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now take our 2021 revenue and multiply by (1 + 4.21%), the 2022 growth rate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I28*(1+J29)",
        references: [ {
            rangeSelector: {
                rowIdx: 27,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 28,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our 2022 revenue forecast is $2,396*(1+0.4.21%) = $2,497."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we copy across the revenue formula one cell to the right."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 9,
            rowCount: 1,
            columnCount: 2
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Then, using the copy-right shortcut we can highlight the revenue formula, our growth rate below and the cells in the remaining periods. Make sure we do not include the step row since it will incorrectly change our inputs. We then press CTRL + R."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This results in our growth rate formula copying across unchanged and revenue for 2029 grows to $3,145."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now when we look at our growth rates, we can see they remain constant (straight-lined) until 2025 when the growth rate decreases by our step of 0.5% for 3 years. After this, the growth rate stabilises at 2.7% until the end of the period."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we forecast for Latin America. This market has experienced weak growth recently, but we can assume that this market is going to grow, and Kellogg is going to win market share. For this reason, we can assume that the growth rate will increase from the average in 2022 by 1% for 2 years and then remain stable until the end of our projections."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To do this we again can include an additional row for a growth rate step which we can input the adjustments for each period we want."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Here we can put the adjustments for our growth rate in 2023 and 2024. We want the growth rate to increase so we input the growth rate to increase by 1%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 33,
        colIdx: 10,
        number: 1.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 33,
        colIdx: 11,
        number: 1.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 33,
        colIdx: 12,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 33,
        colIdx: 13,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 33,
        colIdx: 14,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 33,
        colIdx: 15,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 33,
        colIdx: 16,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can follow similar steps to when we calculated the Europe segment growth rate. We link our 2023 growth rate but also add an adjustment for our growth rate step."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 32,
        colIdx: 10
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J33+K34",
        references: [ {
            rangeSelector: {
                rowIdx: 32,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 33,
                colIdx: 10,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In 2023 there is a 1% adjustment, so the growth rate is higher. Now, we can calculate our revenues."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now take our 2021 revenue and multiply by (1 + 1.86%) the 2022 growth rate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I32*(1+J33)",
        references: [ {
            rangeSelector: {
                rowIdx: 31,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 32,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our 2022 revenue forecast is $997*(1+.1.86%) = $1,016."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Again, we copy the revenue formula one cell to the right"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 9,
            rowCount: 1,
            columnCount: 2
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "And then, using the copy-right shortcut we can highlight the revenue formula, our growth rate below and the cells in the remaining periods. Again, make sure we do not include the step since it will incorrectly change our inputs. We then press CTRL + R."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This results in our growth rate formula copying across and revenue for 2029 grows to $1,311."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now when we look at our growth rates, we can see they increase by 1% in 2023 and 2024 and then remain constant (straight-lined) at 3.9% until the end of the projection period."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we forecast for AMEA. This market has experienced significant growth recently, and we can assume that this market is going to grow further in the short term. Let\u2019s assume that the growth rate increases from the average in 2022 by 5% in the next 2 years after it will decrease modestly by 10%, 7.5% and 5% in the following 3 years before becoming stable in the remaining period."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To do this we again can include an additional row for a growth rate step which we can input the adjustments for each period we want."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 37,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Here we can input the positive 5% adjustments for our growth rate in 2023 and 2024. Following this we can put the tapering negative adjustments -10%, -7.5%, and -5% in 2025, 2026 and 2027, leaving the remaining periods empty."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 37,
        colIdx: 10,
        number: 5.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 37,
        colIdx: 11,
        number: 5.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 37,
        colIdx: 12,
        number: -0.1,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 37,
        colIdx: 13,
        number: -7.5e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 37,
        colIdx: 14,
        number: -5.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 37,
        colIdx: 15,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 37,
        colIdx: 16,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 37,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can use similar steps to when we calculated the Europe and Latin America segment growth rate.  We link our 2023 growth rate but also add an adjustment for our growth rate step."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 36,
        colIdx: 10
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J37+K38",
        references: [ {
            rangeSelector: {
                rowIdx: 36,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 37,
                colIdx: 10,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In 2023 there is a 5% adjustment, so the growth rate is higher. Now, we can calculate our revenues."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now take our 2021 revenue and multiply by (1 + 13.63%) the 2022 growth rate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I36*(1+J37)",
        references: [ {
            rangeSelector: {
                rowIdx: 35,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 36,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our 2022 revenue forecast is $2,613*(1+.13.63%) = $2,969."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Again, we copy the revenue formula one cell to the right."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 9,
            rowCount: 1,
            columnCount: 2
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, using the copy-right shortcut again we can highlight the revenue formula, our growth rate below and the cells in the remaining periods. Again, make sure we do not include the step since it will incorrectly change our inputs. We then press CTRL + R."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 10,
            rowCount: 2,
            columnCount: 7
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This results in our growth rate formula copying across and revenue for 2029 grows to $5,430."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now when we look at our growth rates, we can see they increase by 5% in 2023 and 2024 and then the tapered decline in the periods.  We can check and see that the revenue increases also follow this trend. After 2024 we see a flatter increase in the revenues which confirms the growth rate step is working."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "What is important to remember is that you can justify why you have chosen your assumptions. In your case studies it is often good practice to comment (Shift + F2) in the cell or at the end of the model why you have chosen this number and why you have chosen to reduce (or increase) it."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we use the SUM function to work out the total sales per year which adds the numbers in the cells we select."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(J8,J28,J32,J36)",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 27,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 31,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 35,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our total Sales for 2022 is $8,869+$2,497+$1,106+$$2,969 = $15,350."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can also calculate the implied growth rate $15,350/$14,823-1 = 3.6%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J40/I40-1",
        references: [ {
            rangeSelector: {
                rowIdx: 39,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 39,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, using the copy-right shortcut again we can highlight the total sales formula, our growth rate below and the cells in the remaining periods. We then press CTRL + R."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 9,
            rowCount: 2,
            columnCount: 8
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 9,
            rowCount: 2,
            columnCount: 8
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This results in our growth rate formula copying across and total sales for 2029 grows to $19,128. The 2029 growth rate is 1.3% (relatively low and stable compared to previous years)"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In some case studies, what you have done so far would be enough. However, in some case studies you will be given more granular information and will be asked to incorporate it in your model. Here we have additional information on the product breakdown in the US such as information on morning foods, snacks, specialities and others."
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.ShowGroupedRows({
        startRowIdx: 10,
        endRowIdx: 26
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Alternatively, and often more likely to be done when working is the building of a bottoms-up model. This takes the revenue drivers and forecasting those to see how this impacts revenues for each product. We often want to break down the largest segments of a company and analyse the key inputs that drive the overall revenue of that segment to make our models more robust. This also makes testing our assumptions much easier."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For example, if we broke down the US segment by product, we could look at the volumes and prices of the products they sell."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Hypothetically, suppose we knew that Kellogg expected to deliver 550M US morning snacks in 2022 for an average price of $5 a snack."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 13,
        colIdx: 9,
        number: 550.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 15,
        colIdx: 9,
        number: 5.0,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We could next use historical figures, management guidance, consensus, or our own assumptions to determine how we expect volumes and prices to grow individually.  For example, let\u2019s assume ourselves volumes grow at 2% per year and prices by 4% per year."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 14,
        colIdx: 10,
        number: 2.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 16,
        colIdx: 10,
        number: 4.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To calculate the new volumes, we take the 2022 volumes and multiply it by (1 + 2%) our volume growth rate which is in the cell below."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J14*(1+K15)",
        references: [ {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 14,
                colIdx: 10,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us the 2023 volumes of 550*(1+2%) = 561."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, using the copy-right shortcut we can highlight the volume formula and the cells in the remaining periods."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can reiterate this calculation for the new prices. We take the 2022 price and multiply it by (1 + 4%) our price growth rate which is in the cell below."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 10,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J16*(1+K17)",
        references: [ {
            rangeSelector: {
                rowIdx: 15,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 16,
                colIdx: 10,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us the 2023 price of $5*(1+4%) = $5.20."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, using the copy-right shortcut again we can highlight the price formula and the cells in the remaining periods."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 10,
            rowCount: 1,
            columnCount: 7
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, to calculate the US morning food sales revenue for the period. Starting with 2022 we calculate the product of the volume and price, which we had originally inputted as 550 and $5."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J14*J16",
        references: [ {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 15,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us our 2022 US morning food sales of 550*$5 = $2,750m."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can also calculate the implied growth rate which is $2,750/$2,560-1 = 7.4%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=J11/I11-1",
        references: [ {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, using the copy-right shortcut again we can highlight the revenue formula, the growth rate in the row below, and the cells in the remaining periods."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 9,
            rowCount: 2,
            columnCount: 8
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 9,
            rowCount: 2,
            columnCount: 8
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This results in our growth rate formula copying across and revenue for 2029 grows to $4,157.  The final period growth rate is 6.1%."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "It is important to note that determining the price and volume is where the tricky work appears, but this is when research must be done to support the justifications you decide upon."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "From here you could build per product in each segment to develop a more granular build of the revenues which has more levers that can be pulled when it comes to analysis."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "At this stage you are not expected to know how to complete a full revenue model, however, recall that we covered these simple concepts when looking at the Income Statement in module 1 and how these concepts can build much more intricated models when layered together."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For certain industries such as mining, you may have to understand certain metrics and Key Performance Indicators (KPIs) to better understand the capacity of the company and how this links to its revenue but also its costs and Capex. Do not worry this level of understanding is too advanced for this course."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "An important note is that you could copy and paste many of these growth rate formulae from row to row and in practice we may find opportunities to do this and be efficient. However, we do not recommend it given the likelihood of mistakes occurring and often pasting incorrect formatting."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "However, if you do intend to use copy and paste, we recommend using the copy special CTRL + ALT + V and selecting the formula and pasting the formula across the destination cells. This way you do not take any incorrect formatting with you."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, as will all work in Excel, audit every cell. Use the F2 key to open the cell calculations and check the cell referencing. This is not just for when you copy and paste but for every formula you build. Doing this will catch mistakes and save time when modelling."
    })) ] ];
})();
export {
    businessProjectionsDescriptions
};
