// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var projectingFCFDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In this next section, we will combine the basics of forecasting and the definitions we learned for FCF to begin building our Discounted Cash Flow (DCF). Before we begin let\u2019s populate the key line items, such as revenue, EBIT, Taxes and D&A which we can find in the financial reports, or more likely link from an Excel sheet with the financials spread on it."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Step 1 is to project the revenues. Like in our previous lesson, we could use multiple methods, from assuming a simple growth rate, looking at market size and Kellogg\u2019s market share or forecasting volumes and prices. In this example, we will assume a simple growth rate for total revenue."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 8,
        colIdx: 6,
        number: 2.5e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Taking our 2021 revenues we can grow them by 2.5% each period. We can use (1 + growth rate) to find the growth factor and multiply this by our previous period's revenue."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=F8*(1+G9)",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Instead of copying this out multiple times, we can highlight these 2 cells and then highlight the rightward adjacent cells that we want to continue this formula. We can then use CTRL + R as a shortcut to copy the leftmost cells to the right. This will copy our growth rate assumption and the formula we use to grow revenues."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 6,
            rowCount: 2,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 6,
            rowCount: 2,
            columnCount: 10
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we have implemented the formula for all 10 years without having to manually rewrite it each time. Our 2031 revenue should be $18,153.  This step highlights the importance of trying to be efficient with our work."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 6,
            rowCount: 2,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we must project our costs. We could use a similar process as taught in the previous module where we look at the input costs for producing each product and multiply it by the volumes sold. We could also look at the regional breakdowns. However, for simplicity, we will take the previous margins and assume they continue for the next few years."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now a margin is how much profit as a percentage of revenue we keep, which can be thought of as how much of the revenue is converted into a type of profit, such as gross profit, EBITDA, operating profit (or EBIT) and net income."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To calculate our projected EBIT we first calculate the historical EBIT margin. For 2021 this means we take our EBIT of $1,752 and divide it by our revenue of $14,181."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=F11/F8",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us a 2021 EBIT margin of $1,752/$14,181 = 12.4%"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This means that for every $ of revenue the company makes 12.35% or $0.1235 is left as EBIT, i.e. after costs of goods and sales expenses."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now when we forecast EBIT, we use our historical margin to inform our assumptions of the future. Typically, we can look at the last 3-5 years and determine what the average is for our company and adjust if we think there are any expected changes in the trend of the margin."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The key rule we must follow is by the end of the forecasting we should have a stable margin. This means in the last 2-3 years of our projections; the EBIT margin should be stable."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In this example, we will assume the EBIT margin is the same as in 2021 (known as straight lining) for the entire forecast period for simplicity. So in our assumptions, we can link the 2022 margin to the 2021 calculated historical margin."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 11,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=F12",
        references: [ {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we can calculate our EBIT for 2022, which takes our projected revenue and multiplies it by our EBIT margin. Effectively this is rearranging the formula for the calculation of the EBIT margin."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=G8*G12",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us a 2022 EBIT of $14,536*12.35% = $1,796."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now recall our copy right shortcut (using CTRL + R). We can use it again here by highlighting the EBIT calculation for 2022, the margin assumption, and the columns to the right."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 6,
            rowCount: 2,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 6,
            rowCount: 2,
            columnCount: 10
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Then using CTRL + R we copy across the formula, instead of having to recalculate for each period."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 6,
            rowCount: 2,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can see our margin assumption has remained unchanged throughout the forecasting period and our 2031 EBIT is $2,243."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 15,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "It is important to note that we could have broken this calculation into Gross Profit then Operating Profit and forecast the individual line items in our costs. Equally, we could have linked from a revenue and cost model that drives these lines in more detail."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "However, we are simplifying the process at this point because the intuition and methods of the actual DCF itself would not change."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Following this, we use a similar approach to forecast taxes, D&A and other non-cash expenses. Of course, we could create a large tax and capex schedule however they are too advanced for this course."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For our taxes, more advanced models show how to blend the tax rate based on the sources of the revenue however we will stick to the simple method of assuming a tax rate of 20%. This does differ slightly from the historical due to the difference in taxes expensed and when they are paid but for now let us assume this 20% is relatively accurate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 14,
        colIdx: 6,
        number: 0.2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now to calculate our taxes we take the $1,769 * 20% * (-1) = -$359. Remember the (-1) is important because the tax we pay reduces the cash we have available."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*G11*G15",
        references: [ {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 14,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When we subtract our tax paid from our EBIT to get NOPAT of $1,437."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=G11+G14",
        references: [ {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we forecast the non-cash addbacks. For this, we have taken the historical D&A and other non-cash expenses and found the percentage of revenue this corresponds to (just like a margin calculation)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 19,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=F19/F8",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 18,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We will assume this does not change because the assets are likely to be long-life machinery."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 19,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 19,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=F20",
        references: [ {
            rangeSelector: {
                rowIdx: 19,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Therefore in 2022, we can forecast by multiplying our rate of 3.3% by our projected revenue of $14,536 to get $479."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=G8*G20",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 19,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For Change in Net Working Capital (NWC), the accurate and detailed method involves forecasting the line items on the Balance Sheet, working out the changes over each period and summing this change as we should in the previous lesson."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For example, we can use the first principles approach which involves forecasting various working capital ratios for each item and using these to drive our balance sheet. We would then use the differences in each item to drive our change in NWC. This method is covered in our advanced courses."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "However, a simple way of forecasting can include calculating the change in NWC as a percentage of revenues."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Like with our margins, we do this for historical periods and use this to inform our projections."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For example, if the change in NWC is positive we have increased the proportion of working capital assets to our liabilities. This increase in assets would require funding because they would have to be paid for in cash. This means the increase in NWC would lead to a negative impact on our DCF."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The opposite is also true, so when NWC is negative the working capital liabilities proportion has increased relative to our assets. This increase in liabilities represents cash inflows to the business and so a negative NWC change results in a positive impact to our DCF. For an understanding of each item in NWC, you can refer to our Financial Statements module."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "As we previously worked out the change in NWC was negative 63 in the last period which expressed as a % of the change in revenue gives us 15% (revenue increased by $411). Hence, we will increase cash by 15% each period. This is a simple assumption because it is likely that this would change depending on the seasonality of some goods, but we will use it in this example."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 22,
        colIdx: 6,
        number: 0.15,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 22,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To find our forecast for this line item we multiply the 15% by the CHANGE in our revenue over the period. This involves multiplying 15%*($14,535-$14,181), which adds $53 to our NOPAT."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 21,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(G8-F8)*G23",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 22,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The final projection to make is for Capex. Now in practice, we could build what\u2019s known as a Capex schedule which will account for major purchases and asset sales and can be used to calculate the depreciation each period. However, this goes beyond the scope of this course. Instead, we will use this as a percentage of revenue."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, some important rules to consider about Capex and how it relates to the rest of the model. If we assume this company will be expanding and growing, then we should see our Capex exceed the Depreciation in the corresponding period. Otherwise, we will have a reduction in the asset base. In this case, we would expect to see the % of revenue to be greater for Capex. If they are equal, then we assume there is little growth taking place because any depreciating equipment is simply being replaced with new. If Capex is less, then we would not expect growth since the asset base is eroding."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In our case, we find that the historical capex has been 3.9% of revenues which is higher than our D&A as a % of revenues. This aligns with the growth plans Kellogg had initiated at the time. We would expect these plans to conclude therefore we could model Capex spending to fall as a % of revenue. For this example, we will just assume it remains constant."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 25,
        colIdx: 6,
        number: 3.9e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To calculate our Capex, like most of these line items, we multiply by revenue. This gives us $14,535 * 3.9 * (-1) = -$567 which is netted out of NOPAT."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*G8*G26",
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 25,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our final step in calculating our FCF is to complete the summation as per the formula. NOPAT + D&A and other non-cash expenses + Changes in NWC \u2013 Capex. Of course, we do not include any interest payments or debt payments because we are calculating Unlevered Free Cash Flows. This is also why we recalculate taxes without interest because they would impact the tax savings."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So, for 2022 we take the $1,437 NOPAT, add back the D&A of $479, add $53 in NWC changes and net out the Capex spending of $567."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(G17,G19,G22,G25)",
        references: [ {
            rangeSelector: {
                rowIdx: 16,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 18,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 21,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 24,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us Free Cash Flows of $1,402."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "If we highlight all the cells between Tax Pad and Free Cash Flows from 2022 to 2031 as done with EBIT."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 6,
            rowCount: 15,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 6,
            rowCount: 15,
            columnCount: 10
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Then we use CTRL + R we can copy across our assumptions, our formulae, and our formatting."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 6,
            rowCount: 15,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we can see our 2031 NOPAT has grown to $1,794.  Our D&A and NWC have also increased each period which makes sense given our revenue has increased."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we can note that our FCF in 2031 has increased to $1,750."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can see that when we use CTRL + R, in all the cases in this exercise our formatting for the initial cell is preserved.  This includes the font colour and cell fill colour as with our assumptions, and our borders and the bold toggle for our totals which is the key feature of our copy right shortcut."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we have completed the calculations for all the periods. To recap we have 5 key steps to forecast FCF. 1) We project our Revenue. 2) We use this to project costs that can get us to EBIT (Operating Profit) and calculate the tax we would pay (excluding the interest)."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "3) We calculate the non-cash expenses to add back. 4) We calculate the Capex we expect the business to spend in the period. 5) We use our formulae to calculate Unlevered Free Cash Flows."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The final point to remember is the way we treat line items should always meet a sense check. For example, for growing companies Capex as % Revenue should exceed Depreciation, growth rates should approach low (preferably single digit) figures by the end of the forecasting and margins should stabilise."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "You can now see that the projections we built do fail some of these finer points so feel free to go into the cells and change the assumptions. Look at how changes impact the other line items in the model too. The next section that uses this model will have adjustments to reflect these finer points so you can recall seeing if your thinking was right in the following lessons."
    })) ] ];
})();
export {
    projectingFCFDescriptions
};
