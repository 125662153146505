// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var waccAndDiscountingDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Once we have projected the UFCF (or what we have referred to as FCF in the previous lesson) we can discount those cash flows to be valued in the present day. This relies upon the time value of money because a dollar you are given tomorrow is worth less than a dollar today. This discount reflects the opportunity cost of not having that dollar."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In this case, we use a discount rate for the UFCF because investors could earn a return by investing in other similar companies."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "A higher discount rate indicates there is a higher perceived risk but also higher potential returns. This higher discount rate will reduce the value of our company meaning the investor would need substantial growth and returns to justify the investment. The opposite is true for low discount rates."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So, the discount rate represents the cost to the investors in a business. However, you may ask how we deal with the different types of investors. Well in a capital structure with different types of investors, these investors will have different discount rates."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The first is the Cost of Equity \u2013 representing the opportunity cost for the company\u2019s common stockholders, which they can receive by investing in other similar securities."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 5,
        colIdx: 2,
        text: "Cost of Equity: The opportunity cost for common stock investors who could earn return from capital gains and dividends"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We also have the Cost of Debt \u2013 representing the return for the company\u2019s outstanding debt holders, which is the interest rate they receive."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 6,
        colIdx: 2,
        text: "Cost of Debt:  The cost to the outstanding debt holders who earn a yield from the interest payments and any difference between market value of debt and principal"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "These are the main two but there are also others such as Cost of Preferred Stock \u2013 Which is an equity instrument that has a mandatory dividend which makes the return to investors more akin to a debt instrument. These tend to be more expensive than traditional debt and the payments are not tax-deductible like interest is."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "Cost of Preferred Equity: The cost to the preferred stock holders who earn a dividend similar to the coupons of debt instruments."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we have the core components we will introduce the WACC formula and then delve into how to calculate the components. WACC or the Weighted Average Cost of Capital is the overall discount we use when discounting cash flows. It involves weighting each cost of capital by its proportion in the capital structure. This gives us the following formulae: WACC = Cost of Equity * % Equity + (1 - Tax Rate) * Cost of Debt * % Debt + Cost of Preferred Equity * % Preferred Equity."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 9,
        colIdx: 2,
        text: "WACC = Cost of Equity * % Equity + (1 - Tax Rate) * Cost of Debt * % Debt + Cost of Preferred Equity * % Preferred Equity"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can see that if there was no debt or preferred equity in the capital structure then they would drop out of the equation and the weighting for Cost of Equity would become 1."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Importantly, we have an additional term in front of the debt step whereby we multiply by (1 \u2013 Tax Rate). We do this because the interest payments that make up the Cost of Debt are tax deductible and so save in taxes for our company. This term now reflects that."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Recall the funnel we created to decide whether the FCF was unlevered or levered and which groups of investors the claims represented. Well, WACC corresponds with Unlevered Free Cash Flows because the WACC reflects the costs of all investors and UFCF represents the cash flows they are entitled to."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now if you invested proportionally in the capital structure of this company the WACC is the expected long-term annualised return. You can see the same principle is applied here to the funnel we created."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Walking through a simple example, let us find a company with 25% Debt and 75% Equity. The stock price of similar companies has appreciated 7.5% per year historically and has an average 3.5% dividend yield per year. Our company\u2019s Debt Yield is 8%, and the company's bond price is close to its face value. There is a corporate tax rate of 25%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 2,
            rowCount: 1,
            columnCount: 2
        },
        title: "Inputs"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 2,
        text: "Annual Price Appreciation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 12,
        colIdx: 3,
        number: 7.5e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 2,
        text: "Dividend Yield"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 13,
        colIdx: 3,
        number: 3.5e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 2,
        text: "Debt Yield"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 14,
        colIdx: 3,
        number: 8.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 2,
        text: "Tax Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 15,
        colIdx: 3,
        number: 0.25,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 2,
        text: "% Debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 16,
        colIdx: 3,
        number: 0.25,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 17,
        colIdx: 2,
        text: "% Debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 17,
        colIdx: 3,
        formula: "=1-D17",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 5,
            rowCount: 1,
            columnCount: 2
        },
        title: "Outputs"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 5,
        text: "Cost of Equity"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 5,
        text: "Cost of Debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 5,
            rowCount: 1,
            columnCount: 2
        },
        text: "WACC"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 2,
            rowCount: 7,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, the Cost of Equity: We can simply look at what return an equity investor would get per year. Using the historical performance figures, we have; the Cost of Equity is simply the sum of the annual price appreciation and the dividend yield each year or 7.5% + 3.5%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=D13+D14",
        references: [ {
            rangeSelector: {
                rowIdx: 12,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us a Cost of Equity of 11.0%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next is the Cost of Debt. The current yield of the company\u2019s debt instrument is 8% (not exactly the interest rate but this difference is an advanced topic). This must be adjusted for the tax savings we would achieve because interest is tax-deductible. Therefore, we multiply our yield by 1 minus the tax shield 8%*(1-25%)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=D15*(1-D16)",
        references: [ {
            rangeSelector: {
                rowIdx: 14,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 15,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us a Cost of Debt of 6.0%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we weigh these costs by their proportions in the capital structure. In this case, equity represents 75% of the capital and debt the remaining 25%. There is no preferred equity in this example. So, our final WACC calculation becomes 75% * 11% + 25% * 6%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=D17*G13+D18*G14",
        references: [ {
            rangeSelector: {
                rowIdx: 16,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 17,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 12,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us a WACC of 7.3%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To recap the lesson, the WACC or weighted average cost of capital is the overall discount rate we use when discounting the cash flows of a company. It represents the average return an investor can expect from an investment in the company, blending the cost of equity and the cost of debt. "
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Simply, it is the return investors can expect, or the opportunity cost that the company must exceed for the investment to be net positive. Now we can use the WACC to discount the cash flows of a company to get to a value of the company. This will be covered in the next lesson."
    })) ] ];
})();
export {
    waccAndDiscountingDescriptions
};
